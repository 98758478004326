<template>
  <div class="full-width full-height items-center justify-center flex">
    <a-empty description="暂未开发" v-if="false" />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AiCenter',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
